<template>
  <div >
    <div v-show="!isModify">
      <div class="download-section">
      <div v-if="nowYwChild=='申报入口'" class="xzl">
        <div  class="xzl-xzmb" @click="downloadTemplate">下载模版</div>
        <div class="xzl-div-btn">
          <el-button class="xzl-div-btns" @click="tjfj">
            <i class="el-icon-upload upload-icon"></i>上传excel
          </el-button>

          <!-- 上传excel对话框 -->
          <el-dialog
              title="上传excel"
              :visible.sync="tjfjVisble"
              :close-on-click-modal="false"
          >
            <div class="upload-wrapper">
              <el-upload
                  class="centerImg"
                  :file-list="fileList"
                  ref="uploadicon"
                  :action="url"
                  :on-remove="handleRemove"
                  :before-upload="handleBeforeUpload"
                  :on-change="handleAvatarChangeIcon"
                  accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  multiple
                  drag
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传xlsx文件，且不超过1MB
                </div>
              </el-upload>
            </div>
          </el-dialog>
        </div>
      </div>

      <!-- 查询栏 -->
      <div class="cxl">
        <el-form :inline="true" :model="formLabelAlign" class="demo-form-inline"  label-width="100px">
          <el-form-item label="状态" class="custom-form-item">
            <el-select
                :disabled="showOaPass"
                v-model="formLabelAlign.status"
                clearable
                placeholder="状态"
                class="cxl-input"
            >
            <el-option  label="全部" value="all"></el-option>
            <el-option v-if="!showOaPass" label="正常" value="0"></el-option>
          <el-option v-if="!showOaPass" label="待审核" value="2"></el-option>
              <el-option v-if="!showOaPass" label="导入失败" value="3"></el-option>
              <el-option v-if="!showOaPass" label="审批不通过" value="5"></el-option>
          <el-option v-if="showOaPass" label="删除" value="1"></el-option>
 
              <el-option v-if="showOaPass" label="审批通过" value="4"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
              v-for="(item) in tableQueryForm"
              :key="item.fieId"
              :label="item.fieldName"
          >
          <el-select
      v-if="item.fieldName === '案件状态'"
      v-model="formLabelAlign.caseStatus"
      clearable
      placeholder="选择案件状态"
      class="cxl-input"
  >
      <el-option  label="待交卷，手续未全" value="待交卷，手续未全"></el-option>
      <el-option  label="待交卷，手续已全" value="待交卷，手续已全"></el-option>
      <el-option  label="已立未结" value="已立未结"></el-option>
      <el-option  label="已结案" value="已结案" ></el-option>
      <el-option  label="销案" value="销案"></el-option>
  </el-select>
  <el-select
      v-else-if="item.fieldName === '责任类型'"
      v-model="formLabelAlign.liabilityType"
      clearable
      placeholder="选择责任类型"
      class="cxl-input"
  >
      <el-option  label="全责" value="全责"></el-option>
      <el-option  label="主责" value="主责"></el-option>
      <el-option  label="次责" value="次责"></el-option>
      <el-option  label="同责" value="同责"></el-option>
      <el-option  label="无责" value="无责"></el-option>
  </el-select>
  <el-select
      v-else-if="item.fieldName === '赔付类型' && templateId=='货运险'"
      v-model="formLabelAlign.payOutType"
      clearable
      placeholder="选择赔付类型"
      class="cxl-input"
  >
      <el-option label="买断" value="买断"></el-option>
      <el-option label="维修" value="维修"></el-option>
     
  </el-select>
            <el-input
            v-else
                class="cxl-input"
                :value="formLabelAlign[item.field]"
                @input="updateformLabelAlign(item.field, $event)"
            />
          </el-form-item>

          <!-- <el-form-item label="起保日期" class="custom-form-item">
            <el-date-picker
            class="cxl-input"
                v-model="formLabelAlign.startDate"
                type="date"
                placeholder="选择起保日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
            />
          </el-form-item>

          <el-form-item label="终保日期" class="custom-form-item">
            <el-date-picker
            class="cxl-input"
                v-model="formLabelAlign.endDate"
                type="date"
                placeholder="选择终保日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="endDatePickerOptions"
            />
          </el-form-item> -->
          <el-form-item label="出险日期" class="custom-form-item">
            <el-date-picker
            class="cxl-input"
                v-model="formLabelAlign.accidentDate"
                type="date"
                placeholder="选择出险日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="endDatePickerOptions"
            />
          </el-form-item>
          <el-form-item class="cxl-form-btns">
            <el-button class="cxl-form-btn" @click="onSubmit">查询</el-button>
            <el-button
                class="cxl-form-btn danger-btn"
                @click="exportToExcel"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 表格模块 -->
    <xt-table
        ref="tableComponent"
        :tableTop="tableTop"
        :tableData="tableData"
        :declCount="declCount"
        :insType="formLabelAlign.insType"
        :pageType="formLabelAlign.pageType"
        :showSubmitOaFlag = "!this.showOaPass"
        @isModify="openLp"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @rowData="getNowData"
        @querytable="getTableData"
    />
    </div>
    <!-- 下载模块 -->


    <!-- 编辑模块 -->
    <div v-show="isModify" class="edit-section">
      <h4>编辑信息</h4>
      <el-form ref="form" label-width="80px">
        <el-form-item
            v-for="(item, index) in tableTop"
            :key="item.field"
            :label="item.fieldName"
        >
          <el-select
              v-if="item.field === 'caseNature'"
              v-model="NowData[item.field]"
              placeholder="请选择按键状态"
              class="edit-input"
          >
            <el-option label="已立未决" value="已立未决"></el-option>
            <el-option label="已结案" value="已结案"></el-option>
            <el-option label="销案" value="销案"></el-option>
          </el-select>

          <el-select
              v-else-if="item.field === 'status'"
              v-model="NowData[item.field]"
              placeholder="请选择状态"
              class="edit-input"
          >
            <el-option label="待审核" value="待审核"></el-option>
            <el-option label="审批通过" value="审批通过"></el-option>
          </el-select>
          <el-select
v-else-if="item.field ==='liabilityType'"
              v-model="NowData[item.field]"
              placeholder="请选择责任类型"
              class="edit-input"
          >
            <el-option label="全责" value="全责"></el-option>
            <el-option label="主责" value="主责"></el-option>
            <el-option label="次责" value="次责"></el-option>
            <el-option label="同责" value="同责"></el-option>
            <el-option label="无责" value="无责"></el-option>
          </el-select>
          <el-select
v-else-if="item.field ==='accidentOccurrence'"
              v-model="NowData[item.field]"
              placeholder="请选择事故发生分类"
              class="edit-input"
          >
            <el-option label="运输途中" value="运输途中"></el-option>
            <el-option label="主责" value="主责"></el-option>
            <el-option label="次责" value="次责"></el-option>
            <el-option label="同责" value="同责"></el-option>
            <el-option label="无责" value="无责"></el-option>
          </el-select>
          <el-select
v-else-if="item.field ==='payOutType'"
              v-model="NowData[item.field]"
              placeholder="请选择赔付类型"
              class="edit-input"
          >
            <el-option label="买断" value="买断"></el-option>
            <el-option label="维修" value="维修"></el-option>
          
          </el-select>
          <el-date-picker
              v-else-if="['accidentDate','claimDate', 'endDate', 'settlementDate'].includes(item.field)"
              v-model="NowData[item.field]"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
          />

          <el-input
              v-else
              :value="NowData[item.field]"
              :disabled="['id', 'createdAt'].includes(item.field)"
              @input="updateNowData(item.field, $event)"
              class="edit-input"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="tjBj">确认提交</el-button>
          <el-button @click="qxbj">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// 引入table top 组件
import xtTableTop from "@/components/Public/Tabletop/index.vue";
// 引入table组件
import xtTable from "@/components/Public/Table/index.vue";
import {download} from "@/axios/download.js"; // 导出类型
import axios from 'axios';
import {getDataTop, upLoadlpData, formDataBj, getQueryForm} from "@/axios/tableTop"; // 获取table表格头// 统一更新table数据
// 引入syx.JS
import {lpglClgzzrxData} from "@/axios/Sbpt/Sbpt_sbgl/index.js";
import {
  downloadExporstLpSyx,
  downloadExporstLpJqx,
  downloadExporstLpYggzzrx,
  downloadExporstLpClgzzrx,
  downloadExporstLpHyszx,
  downloadExporstLpCcx,
  downloadExporstLpHyx
} from "@/axios/downList.js";

export default {
  components: {
    xtTable,
    xtTableTop,
  },
  data() {
    const nowYwChild = JSON.parse(localStorage.getItem("insType"));
    return {
      nowYwChild,
      templateId: JSON.parse(localStorage.getItem('ListName')), // 这个就是页面的ref 用来判定需要哪一个模版
      tableTop: [], // 表格头
      tableQueryForm: [], // 不同险种的不同动态查询条件
      tableData: [], // 表格数据
      Usersbxx: {
        // 总申报数量信息
        zsbsl: "",
        bfhj: "",
        sxfhj: "",
      }, // 表格申报信息展示数据 从table总获取
      labelPosition: "right",
      nowYwChild:JSON.parse(localStorage.getItem("insType")),
      formLabelAlign: {
        current: 1,
        size: 50,
        pageType: '',
        insType: "",
        startDate: "",
        endDate: "",
        status: 'all', // 确定是申报入口
        fileName: '', // 下载模版
        operationType:nowYwChild=="申报入口" ? 'filingPortal' : 'ledgerManagement',
        roleId: JSON.parse(localStorage.getItem("roleId")),
        username: JSON.parse(localStorage.getItem("username")),
      },
      isLp: false, // true是理赔 false不是理赔
      isModify: false, // true是编辑 false不是编辑
      fileList: [], // 用于 理赔存储上传的文件列表
      iconformData: [{url: "", name: ""}],
      tjfjVisble: false, // 添加附件
      declCount: 0, // 总条数
      NowData: {}, // 编辑数据
      username: JSON.parse(localStorage.getItem("username")),
      queryDownFile: '', // 下载地址
      url: window.ipConfigUrl.baseURL1 + 'import/decl/vehicleEmpLiabilityIns/InsPriceData',
      templateExporst: [], // 导出模版名称
      showOaPass: false, //是否展示审批通过选项


    };
  },
  computed: {
    // 验证日期选择 起保日期在结束日期之前
    endDatePickerOptions() {
      return {
        disabledDate: (time) => {
          if (!this.formLabelAlign.startDate) {
            return false;
          }
          const startDate = new Date(this.formLabelAlign.startDate);
          return time.getTime() < startDate.getTime();
        },
      };
    },
  },
  methods: {
    updateformLabelAlign(field, value) {
      this.$set(this.formLabelAlign, field, value); // 使用$set确保响应性
    },
    updateNowData(field, value) {
      this.$set(this.NowData, field, value); // 使用$set确保响应性
    },
    // 子组件传参 理赔
    openLp(message, id) {
      this.isModify = message; // 打开编辑
      formDataBj({id: id}).then((res) => {
        // 处理响应
      });
    },
    // 取消 取消理赔
    resetForm() {
      this.isModify = false;
    },
    onSubmit() {
      // 查询发送请求参数给父组件
      // 初始化请求table数据
      this.allData(this.formLabelAlign);
    },
    // 页数变动
    handleSizeChange(e) {
      this.formLabelAlign.size = e;
      this.allData(this.formLabelAlign);
    },
    // 页码变动
    handleCurrentChange(e) {
      this.formLabelAlign.current = e;
      this.allData(this.formLabelAlign);
    },
    // 下载xlsx模版
    downloadTemplate() {
      const typeMap = {
        '01': '商业险.xlsx',
        '02': '车辆雇主责任险.xlsx',
        '03': '交强险.xlsx',
        '04': '货运险.xlsx',
        '05': '财产险.xlsx',
        '06': '责意险.xlsx',
        '07': '货运三者险.xlsx',
        '08': '商品车运输险.xlsx',
      };
      const defaultFileName = "理赔模版-通用模版.xlsx";
      this.fileName = this.formLabelAlign.insType === '04' ? "理赔模版-货运险.xlsx" : defaultFileName;

      download({fileName: this.fileName})
          .then(res => {
            const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = typeMap[this.formLabelAlign.insType] || defaultFileName;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url); // 释放 URL
          })
          .catch(error => {
            console.error('下载失败:', error);
            this.$message.error('文件下载失败，请稍后重试');
          });
    },
    // 点击 上传附件
    tjfj() {
      this.fileList = [];
      this.tjfjVisble = true;
    },
    // 获取编辑参数
    getNowData(message) {
      this.NowData = message;
      this.NowData.username = this.username;
    },
    // 提交编辑
    tjBj() {
      upLoadlpData(this.NowData).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.isModify = false;
        this.allData(this.formLabelAlign);
      });
    },
    // 编辑 取消
    qxbj() {
      this.isModify = false; // 退出编辑页
    },
    // 文件列表移除文件时的钩子
    handleRemove() {
      this.iconformData = [];
    },
    handleBeforeUpload(file) {
      const queryDownFileMap = {
        '01': window.ipConfigUrl.baseURL1 + 'import/claims/propertyIns/insPriceData',
        '02': window.ipConfigUrl.baseURL1 + 'import/claims/vehicleEmpLiabilityIns/insPriceData',
        '03': window.ipConfigUrl.baseURL1 + 'import/claims/compulsoryTrafficIns/insPriceData',
        '04': window.ipConfigUrl.baseURL1 + 'import/claims/cargoIns/insPriceData',
        '05': window.ipConfigUrl.baseURL1 + 'import/claims/commercialIns/insPriceData',
        '06': window.ipConfigUrl.baseURL1 + 'import/claims/empLiabilityIns/insPriceData',
        '07': window.ipConfigUrl.baseURL1 + 'import/claims/cargo3LiabilityIns/insPriceData',
        '08': window.ipConfigUrl.baseURL1 + 'import/claims/vehicleTransportationIns/InsPriceData',
      };
      this.queryDownFile = queryDownFileMap[this.formLabelAlign.insType] || '';
      this.customUpload(file, this.queryDownFile);
      return false; // 阻止默认上传行为
    },
    // 操作添加附件【上传】按钮
    handleAvatarChangeIcon(file, fileList) {
      const isXLSX = file.raw.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXLS = file.raw.name.endsWith(".xls");
      const isCorrectFile = isXLSX || isXLS;
      const isLt2M = file.raw.size / 1024 / 1024 < 1;
      if (!isCorrectFile) {
        this.$message.error("上传excel上传xlsx/xls格式文件!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传excel大小不能超过 1MB!");
        return false;
      }
      return true;
    },
    customUpload(file, downFile) {
      const formData = new FormData();
      formData.append('file', file); // 假设file.raw是文件对象
      formData.append('username', this.username);

      axios.post(downFile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(response => {
            console.log(response,'数据结构');
            
            if (response.data.code == 200) {
              this.$message({
                type: 'success',
                message: response.data.message,
              });
              this.allData(this.formLabelAlign);
              this.tjfjVisble = false;
            } else {
              this.$message({
                type: 'error',
                message: response.data.message,
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    // 导出excel
    exportToExcel() {
      const downloadFuncMap = {
        '01': downloadExporstLpSyx,
        '02': downloadExporstLpClgzzrx,
        '03': downloadExporstLpJqx,
        '04': downloadExporstLpHyx,
        '05': downloadExporstLpCcx,
        '06': downloadExporstLpYggzzrx,
        '07': downloadExporstLpHyszx,
      };
      const downloadFileNameMap = {
        '01': '商业险数据.xlsx',
        '02': '车辆雇主责任险数据.xlsx',
        '03': '交强险数据.xlsx',
        '04': '货运险数据.xlsx',
        '05': '财产险数据.xlsx',
        '06': '责意险数据.xlsx',
        '07': '货运三者险数据.xlsx',
      };
      const downloadFunc = downloadFuncMap[this.formLabelAlign.insType];
      if (downloadFunc) {
        const idArray = this.templateExporst.map(item => item.id);
        downloadFunc(idArray)
            .then(res => {
              const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = downloadFileNameMap[this.formLabelAlign.insType];
              document.body.appendChild(link);
              link.click();
              setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url); // 释放内存
              }, 100);
            })
            .catch(error => {
              console.error('下载失败:', error);
            });
      }
    },
    // 获取表单所有数据
    allData(e) {
            // 加载
            const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
    });
      lpglClgzzrxData(e).then((res) => {
        if (res.data.records2.length>0) {

          this.tableData = res.data.records1 ? res.data.records1.records : [];
          this.declCount = res.data.records1 ? res.data.records1.total: 0; // 总条数
          this.templateExporst = res.data.records2;
          this.$message.success("获取表格数据成功");
          loading.close();
        }
        else{
          this.tableData = [];
          this.declCount = 0; //总条数
          this.templateExporst=[];
          loading.close();
         }
      }).catch(() => {
        this.$message.error("获取数据失败");
        loading.close();
      })
    },
    // 删除成功从新请求数据
    getTableData(e) {
      if (e) {
        this.allData(this.formLabelAlign);
      }
    },
    // 获取当前查询form
    queryForm() {
      getQueryForm({affairType: '1', insType: this.formLabelAlign.insType})
          .then(res => {
            this.tableQueryForm = res.data;
          });
    },
  },
  mounted() {
    const nowYw = JSON.parse(localStorage.getItem("ListName"));
    const nowYwChild = JSON.parse(localStorage.getItem("insType"));

    // 获取当前是申报入口还是台账管理
    if (nowYwChild === '申报入口') {
      this.formLabelAlign.pageType = '1';
      this.formLabelAlign.status = '0';
    } else {
      this.formLabelAlign.pageType = '1';
      this.formLabelAlign.status = '4';
      this.showOaPass = true;
    }

    // 获取默认选中的险种
    const insTypeMap = {
      '责意险': '06',
      '车辆雇主责任险': '02',
      '货运三者险': '07',
      '商业险': '01',
      '交强险': '03',
      '财产险': '05',
      '货运险': '04',
      '商品车运输险': '08',
    };
    this.formLabelAlign.insType = insTypeMap[nowYw] || '';

    // 初始化请求table数据
    this.allData(this.formLabelAlign);
    // 初始化form 查询参数
    this.queryForm();
    // 初始化请求tabletop数据
    getDataTop({pageType: this.formLabelAlign.pageType, insType: this.formLabelAlign.insType})
        .then(res => {
          if (res.code==200) {
            this.tableTop = res.data;
          }else{
            loading.close();;
          }
 
          console.log(this.tableTop, '编辑top');
        });
  },
  deleted(){
    loading.close();
  }
};
</script>

<style lang="less" scoped>
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;

  // 下载模版
  .xzl-xzmb {
    font-size: 12px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(30, 159, 255);
    font-weight: 900;
    cursor: pointer;
    margin-right: 15px;
  }
  .upload-wrapper{
    display: flex;
    justify-content: center;
  }

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin-left: 14px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}

// 查询栏
.cxl {
  border: 1px solid rgb(221, 221, 221);
  position: relative;
  padding: 10px;
  padding-bottom: 0px;

  // input输入框 label
  /deep/ .custom-form-item .el-form-item__label {
    font-size: 12px !important;
    /* 修改字体大小 */
    width: 72px;

    /* 限制宽度 */
    overflow: hidden;
    /* 如果需要，可以添加溢出隐藏 */
    text-overflow: ellipsis;
    /* 文本溢出时显示省略号 */
    white-space: nowrap;
    /* 保持文本在一行内 */
  }

  // input输入框
  .cxl-input {
    width: 200px;
    font-size: 12px;
    height: 30px !important;
    /* 使用 !important 确保覆盖默认样式 */
  }

  // 修改input高度
  /deep/ .el-input__inner {
    height: 26px !important;
  }

  // 修改el-form-item行之间间距
  .el-form-item {
    margin-bottom: 10px !important;
  }

  // 查询项 按钮栏
  .cxl-form-btns {
    position: absolute;
    bottom: 0px;
    right: 30px;

    // 按钮
    .cxl-form-btn {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .cxl-form-btn:hover {
      opacity: 0.8;
      /* 举例使用 Element UI 的主题色作为文字颜色 */
    }
  }
}
</style>
